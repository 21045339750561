import React from "react";
import "./dashboard.css";
import { Outlet, useOutlet } from "react-router-dom";

import profile from "../Dashboard/images/logo.png";
import img1 from "../Dashboard/images/1.jpeg";
import img2 from "../Dashboard/images/3.jpeg";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/authactions";
const Dashboard = () => {
  const outlet = useOutlet();

  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
  };

  return (
    <div>
      <input type="checkbox" id="menu-toggle" />
      <div className="sidebar">
        <div className="side-header">
          <h3>
            <a href="/dashboard">
              <img className="logo" style={{ width: "100px" }} src={profile} />
            </a>
          </h3>
        </div>

        <div className="side-content">
          <div className="profile">
            <img className="profile-img bg-img1" src={img2} />
            <h4>LEGAL DOCS</h4>
            <small>CEO</small>
          </div>

          <div className="side-menu">
            <ul>
              <li>
                <a href="" className="active">
                  <span className="las la-home"></span>
                  <small>Dashboard</small>
                </a>
              </li>
              <li>
                <a href="/dashboard/categories">
                  <span className="las la-envelope"></span>
                  <small>Categories</small>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="las la-envelope"></span>
                  <small>Job Seekers</small>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="las la-clipboard-list"></span>
                  <small>Corporate Training</small>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="las la-shopping-cart"></span>
                  <small>Contacts</small>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="las la-tasks"></span>
                  <small>Tasks</small>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main-content">
        <header>
          <div className="header-content">
            <label htmlFor="menu-toggle">
              <span className="las la-bars"></span>
            </label>

            <div className="header-menu">
              <label htmlFor="">
                <span className="las la-search"></span>
              </label>

              <div className="notify-icon">
                <span className="las la-envelope"></span>
                <span className="notify">4</span>
              </div>

              <div className="notify-icon">
                <span className="las la-bell"></span>
                <span className="notify">3</span>
              </div>

              <li>
                <a href="" onClick={handleLogout}>
                  <span class="material-icons">logout</span>
                </a>
              </li>
            </div>
          </div>
        </header>

        <main>
          {outlet === null && (
            <>
              <div className="page-header">
                <h1>Dashboard</h1>
                <small>Home / Dashboard</small>
              </div>

              <div className="page-content">
                <div className="analytics">
                  <div className="card">
                    <div className="card-head">
                      <h2>107,200</h2>
                      <span className="las la-user-friends"></span>
                    </div>
                    <div className="card-progress">
                      <small>User activity this month</small>
                      <div className="card-indicator">
                        <div
                          className="indicator one"
                          style={{ width: "60%" }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-head">
                      <h2>340,230</h2>
                      <span className="las la-eye"></span>
                    </div>
                    <div className="card-progress">
                      <small>Page views</small>
                      <div className="card-indicator">
                        <div
                          className="indicator two"
                          style={{ width: "80%" }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-head">
                      <h2>$653,200</h2>
                      <span className="las la-shopping-cart"></span>
                    </div>
                    <div className="card-progress">
                      <small>Monthly revenue growth</small>
                      <div className="card-indicator">
                        <div
                          className="indicator three"
                          style={{ width: "65%" }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-head">
                      <h2>47,500</h2>
                      <span className="las la-envelope"></span>
                    </div>
                    <div className="card-progress">
                      <small>New E-mails received</small>
                      <div className="card-indicator">
                        <div
                          className="indicator four"
                          style={{ width: "90%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="records table-responsive">
                  <div className="record-header">
                    <div className="add">
                      <span>Entries</span>
                      <select name="" id="">
                        <option value="">ID</option>
                      </select>
                      <button>Add record</button>
                    </div>

                    <div className="browse">
                      <input
                        type="search"
                        placeholder="Search"
                        className="record-search"
                      />
                      <select name="" id="">
                        <option value="">Status</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <span className="las la-sort"></span> CLIENT
                          </th>
                          <th>
                            <span className="las la-sort"></span> TOTAL
                          </th>
                          <th>
                            <span className="las la-sort"></span> ISSUED DATE
                          </th>
                          <th>
                            <span className="las la-sort"></span> BALANCE
                          </th>
                          <th>
                            <span className="las la-sort"></span> ACTIONS
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/3.jpeg"
                              />
                              <div class="client-info">
                                <h4>Andrew Bruno</h4>
                                <small>bruno@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>-$205</td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>-$205</td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$2171</td>
                          <td>19 April, 2022</td>
                          <td>
                            <span class="paid">Paid</span>
                          </td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/3.jpeg"
                              />
                              <div class="client-info">
                                <h4>Andrew Bruno</h4>
                                <small>bruno@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>-$205</td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>
                            <span class="paid">Paid</span>
                          </td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$2171</td>
                          <td>19 April, 2022</td>
                          <td>
                            <span class="paid">Paid</span>
                          </td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/3.jpeg"
                              />
                              <div class="client-info">
                                <h4>Andrew Bruno</h4>
                                <small>bruno@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>-$205</td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$3171</td>
                          <td>19 April, 2022</td>
                          <td>-$205</td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5033</td>
                          <td>
                            <div class="client">
                              <img
                                className="client-img bg-img1"
                                src="assets/img/1.jpeg"
                              />
                              <div class="client-info">
                                <h4>Exty Bruno</h4>
                                <small>exty@crossover.org</small>
                              </div>
                            </div>
                          </td>
                          <td>$2171</td>
                          <td>19 April, 2022</td>
                          <td>
                            <span class="paid">Paid</span>
                          </td>
                          <td>
                            <div class="actions">
                              <span class="lab la-telegram-plane"></span>
                              <span class="las la-eye"></span>
                              <span class="las la-ellipsis-v"></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
