import React from 'react'

const Templets = () => {
  return (
   <>
       <section class="relative section-padding-100-70 ring-bg">
      <img src="assets/img/core-img/hover-me.png" alt="" class="hover-me" />
      <div class="container">
        <div class="section-heading text-center">
          <span class="section-sub-titel v2">Our Creative Templates</span>
          <h2 class="w-text bold">
            Choose a Template, and Let’s Create Your LegalDocs in Minutes
          </h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="cv-item">
              <img src="assets/img/demos/demo-1.png" alt="App" />
              <div class="info">
                <div class="content">
                  <h4>LegalDocs Template #1</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    quis accumsan nisi Ut
                  </p>
                  <a class="preview-demo" href="#"
                    >See template <i class="fa fa-long-arrow-right"></i
                  ></a>
                  <a class="preview-demo v2" href="#"
                    >Use template <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="cv-item">
              <img src="assets/img/demos/demo-2.png" alt="App" />
              <div class="info">
                <div class="content">
                  <h4>LegalDocs Template #2</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    quis accumsan nisi Ut
                  </p>
                  <a class="preview-demo" href="#"
                    >See template <i class="fa fa-long-arrow-right"></i
                  ></a>
                  <a class="preview-demo v2" href="#"
                    >Use template <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="cv-item">
              <img src="assets/img/demos/demo-3.png" alt="App" />
              <div class="info">
                <div class="content">
                  <h4>LegalDocs Template #3</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    quis accumsan nisi Ut
                  </p>
                  <a class="preview-demo" href="#"
                    >See template <i class="fa fa-long-arrow-right"></i
                  ></a>
                  <a class="preview-demo v2" href="#"
                    >Use template <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 offset-lg-2 col-md-6 col-sm-12">
            <h4 class="w-text mt-50 mb-0 text-center">
              {/* More LegalDocs Templates Will be added Soon, */}
              <a class="section-sub-titel v2" href="#"
                ><span class="line-text"
                  >View more Templates<svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                      style={{animationPlayState: "running"}}
                    ></path></svg></span
              ></a>
            </h4>
          </div>
        </div>
      </div>
    </section>
   
   </>
  )
}

export default Templets