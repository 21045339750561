import React, { useState } from "react";
import axios from "axios";
import { Grid, Paper, Typography, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ROOT_URL from "../../Utlities/apiconfig";
const AddCategories = () => {
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${ROOT_URL}/categoriespost`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);

        setFormData({
          title: "",
          subtitle: "",
          image: null,
        });

        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        // Display toast message for failure
        toast.error("Failed to add category. Please try again later.", {
          autoClose: 3000,
        });
      });
  };

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file }); // Set the file in form data

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setImageName(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageName("");
    setFormData({ ...formData, image: null });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Add Category
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Subtitle"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
            <input
              accept="image/*"
              id="raised-button-file"
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                Upload Image
              </Button>
            </label>
            {image && (
              <div>
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ width: "100px", height: "100px" }}
                />
                <Button onClick={handleCancelImage}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10 }}
            >
              Add Category
            </Button>
          </form>
        </Paper>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default AddCategories;
