import React, { useEffect, useState } from "react";
import axios from "axios";
import ROOT_URL from "../../Utlities/apiconfig";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`${ROOT_URL}/getcategoriesall`)
      .then((res) => {
        console.log("Categories API Response:", res);
        if (res.data && res.data.length > 0) {
          setCategories(res.data);
        } else {
          console.error(
            "Error retrieving categories: No categories found in the response"
          );
        }
      })
      .catch((error) => {
        console.error("Error retrieving categories:", error.message);
      });
  };

  return (
    <>
      <section
        className="our_services_area blue-bg section-padding-100-70"
        id="services"
      >
        <div className="container">
          <div className="section-heading text-center">
            <span className="section-sub-titel">Our Main Features</span>
            <h2 className="wow fadeInUp" data-wow-delay="0.3s">
              Create Your Awesome Documents and Enjoy Our &nbsp; 
              <span className="line-text">
                Key Features
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 150"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                    style={{ animationPlayState: "running" }}
                  ></path>
                </svg>
              </span>
            </h2>
          </div>
          <div className="row">
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3 ">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Lease Aggrement.jpg"
                    className="image_main"
                    alt="Lease Agreement"
                  />
                </div>
                <h6 className="title_main">Lease Agreement</h6>
                <button className="button_main">More Templates </button>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Eviction Notice.jpg"
                    className="image_main"
                    alt="Lease Agreement"
                  />
                </div>
                <h6 className="title_main">Eviction Notice</h6>
                <button className="button_main">More Templates </button>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Non-Disclosure Agreement.jpg"
                    className="image_main"
                    alt="Lease Agreement"
                  />
                </div>
                <h6 className="title_main">Non-Disclosure Agreement</h6>
                <button className="button_main">More Templates </button>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Power of Attorney.png"
                    className="image_main"
                    alt="Lease Agreement"
                  />
                </div>
                <h6 className="title_main">Power of Attorney</h6>
                <button className="button_main"> More Templates </button>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Last Will.jpeg"
                    className="image_main"
                    alt="Lease Agreement"
                  />
                </div>
                <h6 className="title_main">Last Will</h6>
                <button className="button_main">More Templates </button>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
              <div className="box1">
                <div className="image_container">
                  <img
                    src="assets/img/Bill of Sale.jpg"
                    className="image_main"
                    alt="Bill of Sale"
                  />
                </div>
                <h6 className="title_main">Bill of Sale</h6>
                <button className="button_main">More Templates </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Categories;
