import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css"; // Import Owl Carousel CSS
const Feedback = () => {
  // Owl Carousel options
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
  };

  return (
    <>
      {/* <!-- ##### Testimonial Area Start ##### --> */}
      <section class="clients_testimonials_area section-padding-0-100">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-heading text-center">
                <span class="section-sub-titel">What Our Clients Say</span>
                <h2 class="wow fadeInUp" data-wow-delay="0.3s">
                  Listen to What Our &nbsp;
                  <span class="line-text">
                    Clients Say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                        style={{ animationPlayState: "running" }}
                      ></path>
                    </svg>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row justify-content-center wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="col-12 col-lg-5 col-md-10 col-xs-12">
              <img
                class="test-clients"
                src="assets/img/core-img/test.png"
                alt=""
              />
            </div>
            <div class="col-12 col-lg-7 col-md-10 col-xs-10 offset-xs-1 mt-s">
              <OwlCarousel options={options} calssName="client_slides">
                {/* <!-- Single Testimonial --> */}
                <div class="single-testimonial text-left">
                  <div class="icon_wrapper">
                    <i class="fa fa-quote-right"></i>
                  </div>

                  <div class="test-stars">
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                  </div>
                  {/* <!-- Testimonial Feedback Text --> */}
                  <div class="testimonial-description">
                    <div class="testimonial_text">
                      <p>
                        Its a perfect and user friendly platform to create Legal Documents at your fingertips.  I am very satisfied with it.  Go for it.
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-left">
                    {/* <!-- Testimonial Image --> */}
                    <div class="testimonial_image">
                      <img src="assets/img/test-img/1.jpg" alt="" />
                    </div>
                    {/* <!-- Admin Text --> */}
                    <div class="admin_text">
                      <h5>Sunny Khan</h5>
                      <p>One of our Clients</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Single Testimonial --> */}
                <div class="single-testimonial text-left">
                  <div class="icon_wrapper">
                    <i class="fa fa-quote-right"></i>
                  </div>
                  <div class="test-stars">
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                  </div>

                  {/* <!-- Testimonial Feedback Text  --> */}
                  <div class="testimonial-description">
                    <div class="testimonial_text">
                      <p>
                        Its just a pure magic.  Within some minutes, I am able to generate the document and wait, to my surprise.. its so clean and creative stuff.  My Five cents for this.
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-left">
                    {/* <!-- Testimonial Image --> */}
                    <div class="testimonial_image">
                      <img src="assets/img/test-img/2.jpg" alt="" />
                    </div>
                    {/* <!-- Admin Text --> */}
                    <div class="admin_text">
                      <h5>Ajoy Das</h5>
                      <p>One of our Clients</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Single Testimonial --> */}
                <div class="single-testimonial text-left">
                  <div class="icon_wrapper">
                    <i class="fa fa-quote-right"></i>
                  </div>
                  <div class="test-stars">
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                    <span>
                      <i class="fa fa-star"></i>
                    </span>
                  </div>

                  {/* <!-- Testimonial Feedback Text  --> */}
                  <div class="testimonial-description">
                    <div class="testimonial_text">
                      <p>
                       Amazing, just Amazing.  I am loss of words to describe this awesome amd sophisticated platform, which is giving all that you want with few button clicks.  Go for it.
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-left">
                    {/* <!-- Testimonial Image --> */}
                    <div class="testimonial_image">
                      <img src="assets/img/test-img/3.jpg" alt="" />
                    </div>
                    {/* <!-- Admin Text --> */}
                    <div class="admin_text">
                      <h5>Jebin Khan</h5>
                      <p>One of our Clients</p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ##### Testimonial Area End ##### --> */}
    </>
  );
};

export default Feedback;
