import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./categories.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ROOT_URL from "../../Utlities/apiconfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Categories = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${ROOT_URL}/getcategoriesall`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const renderImage = (params) => {
    return (
      <div className="image-container">
        <img src={params.value} alt="Image" />
      </div>
    );
  };

  const handleDeleteCategories = async (categoriesId) => {
    try {
      const response = await axios.delete(`${ROOT_URL}/categoriesdeletebyid`, {
        headers: { id: categoriesId },
      });
      console.log(response.data.message);
      // Display toast message
      // toast.success("categories deleted successfully!");
      // After deletion, fetch the updated list of courses
      toast.success("Course deleted successfully!");
      const updatedCategories = categories.filter(
        (categories) => categories.id !== categoriesId
      );
      setCategories(updatedCategories);
    } catch (error) {
      console.error("Error deleting categories:", error);
    }
  };

  const userColumns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: renderImage,
    },
    { field: "title", headerName: "Title", width: 200 },
    { field: "subtitle", headerName: "Subtitle", width: 200 },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <div className="cellAction">
          <a
            href=""
            className="edit"
            onClick={() =>
              navigate(`/dashboard/updatecategories/${params.row.id}`)
            }
          >
            <button style={{ color: "green" }}>Update</button>
          </a>
          <button
            className="button deleteButton"
            onClick={() => handleDeleteCategories(params.row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    // Handle delete logic
    console.log("Delete ID:", id);
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <a href="addcategories" className="button addButton">
          <button style={{ color: "blue" }}>Add New</button>
        </a>
      </div>
      <DataGrid
        className="datagrid"
        rows={categories}
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        onSelectionModelChange={(newSelection) => {
          setSelectedIds(newSelection);
        }}
      />
      <ToastContainer />
    </div>
  );
};

export default Categories;
