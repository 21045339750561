import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Paper, Typography, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ROOT_URL from "../../Utlities/apiconfig";

const UpdateCategories = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    image: null,
  });

  useEffect(() => {
    fetchCourses();
  }, [id]);

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getcategoriesbyid`, {
        headers: {
          id: id, // Pass the ID in the headers
        },
      });
      const { data } = response.data; // Destructure the data object from the response

      // Set the state using nested fields from the data object
      setFormData({
        title: data.title,
        subtitle: data.subtitle,
        image: data.image,
      });
      setImageUrl(data.image); // Set imageUrl separately
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const updateCourse = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("title", formData.title);
      formDataForUpdate.append("subtitle", formData.subtitle);
      formDataForUpdate.append("image", image);

      await axios.put(`${ROOT_URL}/updatecategoriesbyid`, formDataForUpdate, {
        headers: {
          id: id, // Passing ID in headers
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Course updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating course:", error);
      toast.error("Failed to update course. Please try again later.");
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Update Course
          </Typography>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Subtitle"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
          <input
            accept="image/*"
            id="raised-button-file"
            type="file"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
          {imageUrl !== "" && (
            <div>
              <img
                src={imageUrl}
                alt="Uploaded"
                style={{ Width: "100px", height: "100px" }}
              />
              <Button onClick={handleCancelImage}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={updateCourse}
            style={{ marginTop: 10 }}
          >
            Update
          </Button>
        </Paper>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default UpdateCategories;
