import React, { useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import ROOT_URL from "../../Utlities/apiconfig";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/authactions";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const [signupPopupOpen, setSignupPopupOpen] = useState(false);
  const [formDataRegister, setFormDataRegister] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [formLoginData, setFormLoginData] = useState({
    usernameoremail: "",
    password: "",
  });

  const openLoginPopup = () => {
    setLoginPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };

  const openSignupPopup = () => {
    setSignupPopupOpen(true);
  };

  const closeSignupPopup = () => {
    setSignupPopupOpen(false);
  };

  const preventPopupClose = (e) => {
    e.stopPropagation();
  };

  const handleChangeRegister = (e) => {
    const { name, value } = e.target;
    setFormDataRegister({ ...formDataRegister, [name]: value });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    fetch(`${ROOT_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataRegister),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFormDataRegister({
          name: "",
          email: "",
          password: "",
        });

        toast.success(data.message, { autoClose: 3000 });

        setTimeout(() => {
          navigate("/");
          setSignupPopupOpen(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error in Registration:", error);
        toast.error("Error registering user. Please try again.", {
          autoClose: 3000,
        });
      });
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setFormLoginData({ ...formLoginData, [name]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoginPopupOpen(false);
    axios
      .post(`${ROOT_URL}/login`, formLoginData)
      .then((response) => {
        const userrole = response.data.data.role;
        dispatch(login(userrole)); // Dispatching the login action with the user role

        toast.success(response.data.message, { autoClose: 3000 });

        if (userrole === "admin") {
          navigate("/dashboard");
        } else if (userrole === "user") {
          navigate("/error");
        } else {
          console.error("Unknown user role:", userrole);
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  return (
    <>
      <header className="header-area">
        <div className="classy-nav-container dark breakpoint-off">
          <div className="container">
            <nav
              className="classy-navbar justify-content-between"
              id="dreamNav"
            >
              <a className="nav-brand" href="/">
                <img
                  src="assets/logo/logo.png"
                  alt="logo"
                  style={{ width: "200px", height: "50px" }}
                />
              </a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap">
                    <span className="top"></span>
                    <span className="bottom"></span>
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="about">About Us</a>
                    </li>
                    <li>
                      <a href="#">Legal Docs</a>
                    </li>
                    <li>
                      <a href="contact">Contact</a>
                    </li>
                  </ul>
                  <a
                    href="#"
                    className="btn login-btn mr-im"
                    onClick={openLoginPopup}
                  >
                    Log in
                  </a>
                  <a
                    href="#"
                    className="btn login-btn"
                    onClick={openSignupPopup}
                  >
                    Signup
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {loginPopupOpen && (
        <div className="popup-overlay" onClick={closeLoginPopup}>
          <div className="popupLogin-content" onClick={preventPopupClose}>
            <div
              id="test-popup"
              style={{ background: "transparent", width: "500px" }}
            >
              <div className="top-form-header">
                <h4>Create an Account to save your progress</h4>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Explicabo cupiditate eveniet quaerat beatae deleniti.
                </p>
              </div>
              <form action="#" method="post" id="main_login_form" noValidate="">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="group">
                      <input
                        type="text"
                        name="nameoremail"
                        id="name0"
                        required=""
                        onChange={handleLoginChange}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="group">
                      <input
                        type="password"
                        name="password"
                        id="name1"
                        required=""
                        onChange={handleLoginChange}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Password</label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-5 text-left">
                    <button
                      type="submit"
                      className="btn dream-btn"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>
                  <div className="col-12 col-sm-7 text-left">
                    <p className="mb-0 mt-10">
                      Don't have account? <a href="#">Sign up</a>
                    </p>
                  </div>
                </div>
              </form>
              <div className="other-accounts text-center">
                <p className="w-text">Login with other account</p>
                <div className="footer-social-info">
                  <a href="#">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-google-plus" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {signupPopupOpen && (
        <div className="popup-overlay" onClick={closeSignupPopup}>
          <div className="popupSignup-content" onClick={preventPopupClose}>
            <div id="signup-popup" style={{ width: "700px", height: "580px" }}>
              <div className="top-form-header">
                <h4>Signup Form</h4>
              </div>
              <form
                action="#"
                method="post"
                id="main_Signup_form"
                noValidate=""
              >
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="group">
                      <input
                        type="text"
                        name="name"
                        id="name3"
                        required=""
                        onChange={handleChangeRegister}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Name</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="group">
                      <input
                        type="email"
                        name="email"
                        id="name4"
                        required=""
                        onChange={handleChangeRegister}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="group">
                      <input
                        type="password"
                        name="password"
                        id="name5"
                        required=""
                        onChange={handleChangeRegister}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Password</label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-5 text-left">
                    <button
                      type="submit"
                      className="btn dream-btn"
                      onClick={handleRegister}
                    >
                      Signup
                    </button>
                  </div>
                  <div className="col-12 col-sm-7 text-left">
                    <p className="mb-0 mt-10">
                      Don't have account? <a href="#">Login</a>
                    </p>
                  </div>
                </div>
              </form>
              <div className="other-accounts text-center">
                <p className="w-text">Signup with other account</p>
                <div className="footer-social-info">
                  <a href="#">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-google-plus" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default Header;
