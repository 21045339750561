import React from "react";

const Slider = () => {
  return (
    <>
      {/* <!-- ##### Welcome Area Start ##### --> */}
      <section class="welcome_area demo2 flex align-items-center">
        <div class="container">
          <div class="row align-items-center">
            {/* <!-- Welcome Content --> */}
            <div class="col-12 col-lg-6 col-md-12">
              <div class="welcome-content v2">
                <div class="promo-section">
                  {/* <div class="integration-link light">
                    <span class="integration-icon">
                      <img
                        src="assets/img/svg/img-dollar.svg"
                        width="24"
                        height="24"
                        alt=""
                      />
                    </span>
                    <span class="integration-text">
                      Discover The Easiest ways to Build Your CV!
                    </span>
                  </div> */}
                </div>
                <h1 class="wow fadeInUp" data-wow-delay="0.2s">
                Build Your Legal Forms
                  <span class="line-text">
                    {/* LegalDocs */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                        style={{ animationPlayState: "running" }}
                      ></path>
                    </svg>
                  </span>
                  {/* in Minutes. */}
                </h1>
                <p class="wow fadeInUp" data-wow-delay="0.3s">
                Create Your Free Legal Documents & Contracts Online in Minutes
                </p>
                <div class="dream-btn-group wow fadeInUp" data-wow-delay="0.4s">
                  <a href="#" class="btn dream-btn green-btn mr-3">
                    Choose Template
                  </a>
                  <a href="#" class="btn dream-btn green-btn">
                    {" "}
                    contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ##### Welcome Area End ##### --> */}
    </>
  );
};

export default Slider;
