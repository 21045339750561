import React from "react";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";

const Contact = () => {
  return (
    <>
      <Header />
      {/* <!-- ##### Welcome Area Start ##### --> */}
      <div class="breadcumb-area clearfix">
        {/* <!-- breadcumb content --> */}
        <div class="breadcumb-content">
          <div class="container h-100">
            <div class="row h-100 align-items-center">
              <div class="col-12">
                <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                  <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">
                    Contact Us
                  </h2>
                  <ol
                    class="breadcrumb justify-content-center wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ##### Welcome Area End ##### --> */}

      {/* <!-- ##### Contact Area Start ##### --> */}
      <section class="section-padding-100 contact_us_area" id="contact">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-heading text-center">
                {/* <!-- Dream Dots --> */}
                <div
                  class="dream-dots justify-content-center wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <h2 class="wow fadeInUp" data-wow-delay="0.3s">
                  Contact With Us
                </h2>
                <p class="wow fadeInUp" data-wow-delay="0.4s">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.
                </p>
              </div>
            </div>
          </div>

          {/* <!-- Contact Form --> */}
          <div class="row justify-content-center">
            <div class="col-12 col-lg-4">
              <ul class="services-block-four">
                <li class="inner-box">
                  <div class="icon-font-box">
                    <i class="fa fa-envelope-o"></i>
                  </div>
                  <h3>Support Email</h3>
                  <div class="text width-80">Hello@yourdomain.com</div>
                </li>
                <li class="inner-box">
                  <div class="icon-font-box">
                    <i class="fa fa-phone"></i>
                  </div>
                  <h3>Phone Number</h3>
                  <div class="text width-80">(039) 245 90238</div>
                </li>
                <li class="inner-box">
                  <div class="icon-font-box">
                    <i class="fa fa-calendar-o"></i>
                  </div>
                  <h3>Working Hours</h3>
                  <div class="text width-80">9AM to 5PM Sunday To Friday</div>
                </li>
                <li class="inner-box">
                  <div class="icon-font-box">
                    <i class="fa fa-map-o"></i>
                  </div>
                  <h3>Location</h3>
                  <div class="text width-80">
                    72 St, Newyork, Downtown Tower
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-8">
              <div class="contact_form grediant-borders">
                <form
                  action="#"
                  method="post"
                  id="main_contact_form"
                  novalidate=""
                >
                  <div class="row">
                    <div class="col-12">
                      <div id="success_fail_info"></div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="group fadeInUp" data-wow-delay="0.2s">
                        <input type="text" name="name" id="name" required="" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Name</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="group fadeInUp" data-wow-delay="0.3s">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          required=""
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Email</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="group fadeInUp" data-wow-delay="0.4s">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          required=""
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Subject</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="group fadeInUp" data-wow-delay="0.5s">
                        <textarea
                          name="message"
                          id="message"
                          required=""
                        ></textarea>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Message</label>
                      </div>
                    </div>
                    <div
                      class="col-12 text-center fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      <button type="submit" class="btn dream-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ##### Contact Area End ##### --> */}
      <Footer />
    </>
  );
};

export default Contact;
