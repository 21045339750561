import React from "react";
import Header from "./Header/Header";
import Slider from "./Slider/Slider";
import { Footer } from "./Footer/Footer";
import Feedback from "./Feedback/Feedback";
import Categories from "./Categories/Categories";
import Templets from "./Sampletemplets/Templets";
import Easysteps from "./Easysteps/Easysteps";

const Blog = () => {
  return (
    <>
      <Header />
      <Slider />
      <Categories />
      <Easysteps />
      <Templets />
      <Feedback />
      <Footer />
    </>
  );
};

export default Blog;
