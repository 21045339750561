import React from "react";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import Easysteps from "../Easysteps/Easysteps";

const About = () => {
  return (
    <>
      <Header />
      {/* <!-- ##### Welcome Area Start ##### --> */}
      <div class="breadcumb-area">
        {/* <!-- breadcumb content --> */}
        <div class="breadcumb-content">
          <div class="container h-100">
            <div class="row h-100 align-items-center">
              <div class="col-12">
                <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                  <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">
                    About us
                  </h2>
                  <ol
                    class="breadcrumb justify-content-center wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ##### Welcome Area End ##### --> */}
      <Easysteps />

      <Footer />
    </>
  );
};

export default About;
