import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "../Blog/Blog";
import Dashboard from "../Dashboard/Dashboard";
import Categories from "../Dashboard/Categories/Categories";
import AddCategories from "../Dashboard/Categories/AddCategories";
import UpdateCategories from "../Dashboard/Categories/UpdateCategories";
import About from "../Blog/About/About";
import Contact from "../Blog/Contact/Contact";
import AdminProtectedRoute from "../Authentication/Adminprotectedroute";
import Error from "../Blog/Pages/Error";
// import Error from "../Blog/Pages/Error";
const Rootstock = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
        <Route
          path="/dashboard"
          element={<AdminProtectedRoute element={<Dashboard />} />}
        >
          <Route
            path="categories"
            element={<AdminProtectedRoute element={<Categories />} />}
          />
          <Route
            path="addcategories"
            element={<AdminProtectedRoute element={<AddCategories />} />}
          />
          <Route
            path="updatecategories/:id"
            element={<AdminProtectedRoute element={<UpdateCategories />} />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default Rootstock;
