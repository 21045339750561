import React from 'react'

const Easysteps = () => {
  return (
    <>
        <div class="blue-bg">
      {/* <!-- ##### About Us Area Start ##### --> */}
      <section class="about-us-area section-padding-100 blue-bg">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-lg-5">
              <div
                class="welcome-meter wow fadeInUp mt-s"
                data-wow-delay="0.3s"
              >
                <img
                  src="assets/img/core-img/about1.png"
                  class="center-block"
                  alt=""
                />
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="who-we-contant mt-s">
                {/* <span class="section-sub-titel">Learn about us</span> */}
                <h4>Remove Headache of Creating a LegalDoc!</h4>
                <p>
                 No need to hire an expert, No need to stay in the line, just use Our
                 Easiest, yet technically well built tool to produce LegalDocs in a matter of
                 Minutes.  All at your Doorstep.

                </p>
                <a class="btn dream-btn mt-15" href="#"
                  >lets build your LegalDoc</a
                >
              </div>
            </div>
            <div class="col-12 col-lg-3 mt-s">
              <div class="who-we-contant">
                <div class="list-wrap align-items-center">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Proven LegalDoc Templates
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Creative and Clean Templates Design
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Easy and Intuitive Online LegalDoc Builder
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Free to use. Developed by Legal professionals.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Fast Easy LegalDocs Formatting
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="side-feature-list-item">
                        <img
                          src="assets/img/icons/check.png"
                          class="check-mark-icon"
                          alt=""
                        />
                        <div class="foot-c-info">
                          Legally Approved Phrases.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ##### About Us Area End ##### --> */}

      <section class="features-sec section-padding-0-70">
        <div class="container">
          <div class="section-heading text-center">
            <span class="section-sub-titel">How it Works</span>
            <h2 class="bold">
              Easy Steps To
              <span class="line-text"
                >Build your LegalDocs<svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 150"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                    style={{ animationPlayState: 'running' }}
                  ></path></svg
              ></span>
            </h2>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="how-it-work">
                <div class="work-icon-wrap">
                  <img src="assets/img/icons/1.svg" class="mb-10" alt="" />
                </div>
                <p>Step Num <span class="gradient-text cyan">#1</span></p>
                <h3 class="article__title">Create your Account</h3>
                <span class="process-devider"></span>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="how-it-work">
                <div class="work-icon-wrap">
                  <img src="assets/img/icons/3.svg" class="mb-10" alt="" />
                </div>
                <p>Step Num <span class="gradient-text cyan">#2</span></p>
                <h3 class="article__title">Choose Your Legal Form</h3>
                <span class="process-devider"></span>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="how-it-work">
                <div class="work-icon-wrap">
                  <img src="assets/img/icons/4.svg" class="mb-10" alt="" />
                </div>
                <p>Step Num <span class="gradient-text cyan">#3</span></p>
                <h3 class="article__title">Answer Simple Questions</h3>
                <span class="process-devider"></span>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="how-it-work">
                <div class="work-icon-wrap">
                  <img src="assets/img/icons/5.svg" class="mb-10" alt="" />
                </div>
                <p>Step Num <span class="gradient-text cyan">#4</span></p>
                <h3 class="article__title">Download Your LegalDocs</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    </>
  )
}

export default Easysteps