// authReducer.js
const initialState = {
  userRole: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userRole: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        userRole: null,
      };
    default:
      return state;
  }
};

export default authReducer;
