import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminProtectedRoute = ({ element, ...rest }) => {
  const userRole = useSelector((state) => state.auth.userRole);

  if (userRole !== "admin") {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default AdminProtectedRoute;
