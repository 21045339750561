import React from "react";

export const Footer = () => {
  return (
    <>
      {/* <!-- ##### Footer Area Start ##### --> */}
      <footer class="footer-area bg-img">
        <div class="footer-content-area spec">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-4 col-md-6">
                <div class="footer-copywrite-info">
                  {/* <!-- Copywrite --> */}
                  <div class="copywrite_text fadeInUp" data-wow-delay="0.2s">
                    <div class="footer-logo">
                      <a href="#">
                        <img src="assets/img/footer-logo.png" alt="logo" style={{width: "150px", height: "40px"}}/>
                      </a>
                    </div>
                    <p>
                      LegalDocs is a Professional and dedicated platform to serve the clients who want to design their Legal Documents hassle free.
                      Our creative stuff is enough for our clients to give what they want, without consulting any Legal Advisor.  Infact, its created with Legal experts carefully examining every aspect of the documents that it is going to create.
                    </p>
                  </div>
                  {/* <!-- Social Icon --> */}
                  <div
                    class="footer-social-info fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <a href="#">
                      <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-3 col-md-6">
                <div class="contact_info_area d-sm-flex justify-content-between">
                  {/* <!-- Content Info --> */}
                  <div
                    class="contact_info mt-x text-center fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <h5>PRIVACY & TOS</h5>
                    <a href="#">
                      <p>Advertiser Agreement</p>
                    </a>
                    <a href="#">
                      <p>Acceptable Use Policy</p>
                    </a>
                    <a href="#">
                      <p>Privacy Policy</p>
                    </a>
                    <a href="#">
                      <p>Technology Privacy</p>
                    </a>
                    <a href="#">
                      <p>Developer Agreement</p>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-2 col-md-6">
                {/* <!-- Content Info --> */}
                <div class="contact_info_area d-sm-flex justify-content-between">
                  <div
                    class="contact_info mt-s text-center fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <h5>NAVIGATE</h5>
                    <a href="#">
                      <p>Advertisers</p>
                    </a>
                    <a href="#">
                      <p>Developers</p>
                    </a>
                    <a href="#">
                      <p>Resources</p>
                    </a>
                    <a href="#">
                      <p>Company</p>
                    </a>
                    <a href="#">
                      <p>Connect</p>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-3 col-md-6">
                <div class="contact_info_area d-sm-flex justify-content-between">
                  {/* <!-- Content Info --> */}
                  <div
                    class="contact_info mt-s text-center fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <h5>CONTACT US</h5>
                    <p>Our Address:</p>
                    <p>xxxxx xxxxx xxxxx</p>
                    <p>+999 999 99999</p>
                    <p>support@legaldocs.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ##### Footer Area End ##### --> */}
    </>
  );
};
