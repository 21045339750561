// import logo from '../pub';
import "./App.css";
import Rootstock from "./Components/Navigation/Rootstock";

function App() {
  return (
    <>
      <Rootstock />
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src="assets/logo/logo.png" className="App-logo" alt="logo" />
    //     <p style={{color: '#fff'}}>
    //       Edit your Legal Docs
    //     </p>
    //     {/* <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a> */}
    //   </header>
    // </div>
  );
}

export default App;
