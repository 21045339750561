import React from "react";
// import Header from "../Header/Header";
// import Newsletter from "../Newsletter/Newsletter";
// import Footer from "../Footer/Footer";

const Error = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>404 Page</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="404-wrap">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="four-zero-page">
                  <h2>404</h2>
                  <h3>Sorry Page Was Not Found</h3>
                  <p>
                    The page you are looking is not available or has been
                    removed. Try going to Home Page by using the button below.
                  </p>
                  <div className="readmore">
                    {" "}
                    <a href="/">Go To Homepages</a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Newsletter /> */}
      {/* <Footer /> */}
    </>
  );
};

export default Error;
